import { environment } from './../../../environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import Amplify, { Auth, API } from 'aws-amplify';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import Storage from '@aws-amplify/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalstorageService } from 'src/app/services/localstorage.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': '',
  })
};
@Injectable({
  providedIn: 'root'
})
export class StudentService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': '',
    })
  };
  private apiUrl: string;
  private apiEmailUrl: string;
  private userSub;
  myDate = new Date()
  date: string;
  totalPoints: any;
  public s3Image = '';
  apiUrlCarrer: string;
  apiUrlFeedBack: String;
  apiUrlActivityLog: string;

  SharingDataPersonal = new Subject();
  SharingDataEducation = new Subject();
  SharingDataFamily = new Subject();
  SharingDataBasic = new Subject();
  SharingDataOther = new Subject();
  totalUpdatePoints = new Subject();
  updateBasicName = new Subject();
  studentData: any = {}
  bulkEditIntimate = new Subject();
  clusertInfo = new Subject();
  careerInfo = new Subject();
  updateEducationVal = new Subject();
  navbarData = [
    {title:"Home", routerLink:'/student/dashboard', icon:'home', active:false},
    {title:"Profile", routerLink:'/student/profile', icon:'avatar', active:false},
    {title:"Assessment", routerLink:'', icon:'test', active:false,opened:false,
      children:[
        {title:"My Assessments", routerLink:'/assessment', icon:'sub-menu', active:false},
        // {title:"My Analytics", routerLink:'/assessment/report/interestReport', icon:'sub-menu', active:false},
      ]
    },
    {title:"Careers", routerLink:'', icon:'careers', active:false,opened:false,
      children:[
        // {title:"All Streams", routerLink:'/career/all-stream', icon:'sub-menu', active:false},
        {title:"All Careers", routerLink:'/career/all-cluster', icon:'sub-menu', active:false},
        {title:"Career Quiz", routerLink:'/career/quiz', icon:'sub-menu', active:false},
        // {title:"My Recommendations", routerLink:'/career/recommendations', icon:'sub-menu', active:false},

      ]
    },
  //   {title:"Report", routerLink:'', icon:'Report-Regular', active:false,opened:false,
  //   children:[
  //     {title:"My Report", routerLink:'/assessment/fullReport', icon:'sub-menu', active:false},
  //   ]
  // },
  // {title:"Pricing", routerLink:'/pricing/plans', icon:'pricing3', active:false},

  ]

  backupNavbarData = [
    {title:"Home", routerLink:'/student/dashboard', icon:'home', active:false},
    {title:"Profile", routerLink:'/student/profile', icon:'avatar', active:false},
    {title:"Assessment", routerLink:'', icon:'test', active:false,opened:false,
      children:[
        {title:"My Assessments", routerLink:'/assessment', icon:'sub-menu', active:false},
        // {title:"My Analytics", routerLink:'/assessment/report/interestReport', icon:'sub-menu', active:false},
      ]
    },
    {title:"Careers", routerLink:'', icon:'careers', active:false,opened:false,
      children:[
        // {title:"All Streams", routerLink:'/career/all-stream', icon:'sub-menu', active:false},
        {title:"All Careers", routerLink:'/career/all-cluster', icon:'sub-menu', active:false},
        {title:"Career Quiz", routerLink:'/career/quiz', icon:'sub-menu', active:false},
        // {title:"My Recommendations", routerLink:'/career/recommendations', icon:'sub-menu', active:false},

      ]
    },
  //   {title:"Report", routerLink:'', icon:'Report-Regular', active:false,opened:false,
  //   children:[
  //     {title:"My Report", routerLink:'/assessment/fullReport', icon:'sub-menu', active:false},
  //   ]
  // },
  // {title:"Pricing", routerLink:'/pricing/plans', icon:'pricing3', active:false},

  ]


  //private user_sub:string;
  constructor(private _snackBar: MatSnackBar, private http: HttpClient, private _authService: AuthService, private _localStorage: LocalstorageService,) {

    this._authService.getCurrentUserObj().then(data => {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': data.signInUserSession.idToken.jwtToken,
        })

      };
      //this.http.options(this.httpOptions);


    }).catch(error => { console.log(error) })


    const BASE_API_URL = environment.ApiConfig.student;
    const BASE_API_URL_FEEDBACK = environment.ApiConfig.others;
    Storage.configure({
      AWSS3: {
        bucket: 'studentuserprofile', //REQUIRED -  Amazon S3 bucket name
        region: 'ap-south-1' //OPTIONAL -  Amazon service region
      }
    });
    this.date = moment().format("YYYY-MM-DD HH:mm:ss");

    this.apiUrl = `${BASE_API_URL}/student/profile`;

    this.apiUrlCarrer = `${BASE_API_URL}`;
    this.apiUrlFeedBack = `${BASE_API_URL_FEEDBACK}`;
    this.apiUrlActivityLog = `${BASE_API_URL}/student/activitylog`;
    this.apiEmailUrl = `${BASE_API_URL}/student`;
    this.userSub = sessionStorage.getItem('userSub')
    this._authService.getCurrentUserObj().then(data => {
      this.userSub = data.attributes.sub;
    }).catch(error => { })

  }

  showMessageForAdmin(massage, colorClass) {
    this._snackBar.open(massage, 'x', {
      duration: 3 * 1000,
      verticalPosition: 'bottom',
      panelClass: [colorClass]
    });
  }

  getCurrentDate() {
    return moment().format("YYYY-MM-DD HH:mm:ss");

  }
  updateSub() {
    this.userSub = sessionStorage.getItem('userSub')
  }
  //GET
  getStudentDetails(section) {
    this.updateSub();
    if (section == 'basic' || section == 'education' || section == 'family' || section == 'personal') {
      let finalURL = `${this.apiUrl}/${section}/${this.userSub}`
      if (section == 'basic' || section == 'education' || section == 'family' || section == 'personal') {
        finalURL += '?entity_id=' + environment.entity_id
      }
      return this.http.get(finalURL, this.httpOptions);
    }
  }
  getStudentBasic(section, uuid) {
    if (section == 'basic' || section == 'education' || section == 'family' || section == 'personal') {
      let finalURL = `${this.apiUrl}/${section}/${uuid}`
      if (section == 'basic') {
        finalURL += '?entity_id=' + environment.entity_id
      }
      return this.http.get(finalURL, this.httpOptions);
    }
  }


  dashboardSearchCluster(str) {
    return this.http.get(`${this.apiUrlCarrer}/student/dashboard/search?q=${str}`, this.httpOptions);
  }

  //GET
  getCareerClusters() {
    let userSub = sessionStorage.getItem('userSub')
    return this.http.get(`${this.apiUrlCarrer}/student/career/clusters?sub=${userSub}`, this.httpOptions);
  }

  updateTradingApi(tradingData) {
    console.log('tradingData service--------------------', tradingData)
    return this.http.patch(`${this.apiEmailUrl}/dashboard/search/counter`, tradingData, this.httpOptions);
  }
  //GET
  getAllCareers() {
    return this.http.get(`${this.apiUrlCarrer}/student/career/careers`, this.httpOptions);
  }

  getRecommendedCareers() {
    let userSub = sessionStorage.getItem('userSub');
    return this.http.get(`${this.apiUrlCarrer}/student/career/recommendations?sub=${userSub}`, this.httpOptions);
  }

  getDashboardwidgets() {
    let userSub = sessionStorage.getItem('userSub');
    return this.http.get(`${this.apiUrlCarrer}/student/dashboard/widgets/?id=${userSub}`, this.httpOptions);
  }


  getTrendingCareers() {
    return this.http.get(`${this.apiUrlCarrer}/student/dashboard/trending`, this.httpOptions);
  }


  updateStudentDetails(userAttributes, section) {
    //userAttributes.updated_at =this.getCurrentDate();;
    if (section == 'basic' || section == 'education' || section == 'family' || section == 'personal') {
      if (section == 'basic') {
        userAttributes.entity_id = environment.entity_id
      }
      return this.http.patch(`${this.apiUrl}/${section}/${this.userSub}`, userAttributes, this.httpOptions);
    }
  }

  maskUpdateStudentDetails(userAttributes) {
    // userAttributes.updated_at =this.getCurrentDate();
    userAttributes.entity_id = environment.entity_id
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': '',
      }),
      body: userAttributes
    };
    return this.http.patch(`${this.apiUrl}/basic`, userAttributes, this.httpOptions);

  }

  getRewards() {
    return this.http.get(`${this.apiUrl}/rewards/api/rewards`, this.httpOptions);
  }

  getSubscriptionPlansList() {
    let entity_id = environment.entity_id
    return this.http.get(`${this.apiEmailUrl}/subscription-plans?entity_id=${entity_id}`, this.httpOptions);
  }

  maskDeleteStudentDetails(data) {

    //this.httpOptions.params = new HttpParams({uuid:uuid})
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': '',
      }),
      body: data
    };
    return this.http.delete(`${this.apiUrl}/basic`, httpOptions);
  }

  updateRewardPoints(updatedData) {
    //updatedData.updated_at =this.getCurrentDate();
    return this.http.patch(`${this.apiUrl}/rewards/calculate`, updatedData);
  }
  addEmailNotification(userAttributes, section) {
    console.log('userAttributes', userAttributes)
    userAttributes.updated_at = this.getCurrentDate();;
    return this.http.post(`${this.apiEmailUrl}/${section}`, userAttributes, this.httpOptions);
  }

  addStudentDetails(userAttributes, section) {
    userAttributes.created_at = this.getCurrentDate();;
    userAttributes.updated_at = this.getCurrentDate();;

    if (section == 'basic' || section == 'education' || section == 'family' || section == 'personal') {
      // userAttributes.uuid = this.userSub;
      if (section == 'basic') {
        userAttributes.entity_id = environment.entity_id
      }
      if (section == 'personal') {
        return this.http.post(`${this.apiUrl}/${section}/${this.userSub}`, userAttributes, this.httpOptions);
      }
      else {
        userAttributes.uuid = this.userSub;
        return this.http.post(`${this.apiUrl}/${section}`, userAttributes, this.httpOptions);
      }

    }
  }

  fetchProfilePic() {
    this._authService.getCurrentUserObj().then(data => {
      /*if(data.attributes.email_verified) {
        this.userProfileForm.get('email').setValue(data.attributes.email)
      }*/
      // Storage.list()
      Storage.get(data.attributes.sub).then((data: any) => {
        fetch(data).then(response => {
          if (response.status == 200) {
            this.s3Image = data;
          }
        }).catch(error => {
          console.log(error)
        })
        //this.imageUrl = data;
      }).catch(error => { console.log(error) })
    });
  }

  from: any = 1200;
  to: any = 1400;
  increment() {
    setTimeout(() => {
      this.from++;
      if (this.from <= this.to) {
        this.totalPoints = this.from;
        this.increment();
      }
    }, 20);
  }

  incrementDynamic(from, to, ms = 20) {
    if (from != false && to != false) {
      this.from = from;
      this.to = to;
    }
    setTimeout(() => {
      this.from += 2;
      if (this.from <= this.to) {
        this.totalPoints = this.from;
        this.incrementDynamic(false, false, ms);
      }
    }, ms);
  }


  highlightLeftMenu(url, type) {
    var tempData1 = [];
    var tempData = [];
    tempData1 = JSON.parse(JSON.stringify(this.backupNavbarData));
    tempData = tempData1;
    if (type == 'child') {
      tempData.forEach((element, index) => {
        if (element.children && element.children.length > 0) {
          element.children.forEach((child) => {
            if (child.routerLink == url) {
              element.active = true;
              element.opened = true;
              child.active = true;
            }
          });
        }
      })
    }
    if (type == 'parent') {
      tempData.forEach((element1, index) => {
        if (element1.children && element1.children.length > 0) {
          element1.children.forEach(child => {
            child.active = false;
            element1.opened = false;
            element1.active = false;
          });
        }
      })
    }
    let cloneData: any = [];
    cloneData = JSON.parse(JSON.stringify(tempData));
    this.navbarData = cloneData;
    this._localStorage.set('sidebarData', this.navbarData);
  }

  //feedback
  saveFeedBack(userAttributes, entityId = false) {
    if (entityId) {
      // commented on 04112022
      // userAttributes.entity_id = entityId
      userAttributes.entity_id = environment.entity_id
    }
    else {
      userAttributes.entity_id = environment.entity_id
    }
    console.log('userAttributes------------', userAttributes)
    return this.http.post(`${this.apiUrlFeedBack}/feedback`, userAttributes, this.httpOptions);
  }

  //25052023 active user service
  saveActiveUserDetails(oActivityLogDetails) {
    return this.http.post(`${this.apiUrlActivityLog}`, oActivityLogDetails, this.httpOptions);
  }

}
